import { acceptHMRUpdate, defineStore } from 'pinia';
import { useBaseStore } from '@store';
import clone from '@utils/useClone';
import { UseRefHistoryRecord } from '@/types/app';
import {
	CreateWorkflowType,
	WorkflowDisplayMode,
	WorkflowElementAction,
	WorkflowElementActions,
	WorkflowElementSettings,
	WorkflowExit,
	WorkflowForms,
	WorkflowObjectType,
	WorkflowSchema,
	WorkflowSettings,
	WorkflowTriggers,
	WorkflowVariable,
	WorkflowVariables,
} from '@/types/workflow';
import { ActionTypes, initElement, WorkblockSections } from '@modules/workflow/utils/constants';
import { convertEmptyArraysToObjects, convertJSAddressToPHPAddress, makePositionToElements, postAddActionToSchema, removePlus } from '@modules/workflow/utils/helpers';
import { WorkblockAction, WorkblockExpressionFunction, WorkblockTemplate, Workflow } from '@/types/graphql';
import { GET_ACTIVE_WORKFLOWS_DROPDOWN, GET_WORKBLOCK_ACTIONS, GET_WORKBLOCK_EXPRESSION_FUNCTIONS, GET_WORKBLOCK_TEMPLATES, GET_WORKFLOW_BY_ID, GET_WORKFLOWS } from '@modules/workflow/graphql/WorkflowQueries';
import { CREATE_NEW_WORKFLOW, UPDATE_WORKFLOW } from '@modules/workflow/graphql/WorkflowMutations';
import useNotify from '@utils/useNotify';
import { AlertIcons } from '@/types/dialog';
import { get } from 'lodash';
import { nanoid } from 'nanoid';
import useGraphQL from '@utils/useGraphQL';
import { safeUnpack } from '@utils/helpers';
import { ActivityTemplateVariableType } from '@/types/activityTemplate';

export const useWorkflowStore = defineStore({
	id: 'Workflow',
	state: () => ({
		loaded: false,
		loading: true,
		showDetail: false,
		displayMode: WorkflowDisplayMode.Builder,
		workflow: <Workflow>{},
		workflows: <Workflow[]>[],
		workflowSchema: <WorkflowSchema>{},
		workflowSettings: <WorkflowSettings>{},
		workflowForms: <WorkflowForms>{},
		workflowTriggers: <WorkflowTriggers>{},
		workflowVariables: <WorkflowVariables>{},
		workflowExits: <WorkflowExit>{},
		elementSettingsOpen: false,
		workflowSettingsOpen: false,
		workflowEditingElementAddress: '',
		workflowEditingElementIndex: -1,
		undoStack: <UseRefHistoryRecord<WorkflowSchema>[]>[],
		redoStack: <UseRefHistoryRecord<WorkflowSchema>[]>[],
		historyCapacity: <number>20,
		draggingElement: <WorkblockTemplate | boolean>false,
		workblockTemplates: <WorkblockTemplate[]>[],
		workblockActions: <WorkblockAction[]>[],
		workblockExpressionFunctions: <WorkblockExpressionFunction[]>[],
		workflowElementsModal: {
			open: false,
			insertAddress: '',
			insertIndex: -1,
		},
		workflowProcessHistory: [],
	}),
	getters: {
		isWorkflowLoaded: (state): boolean => state.loaded,
		getShowDetail: (state): boolean => state.showDetail,
		getDisplayWorkflow: (state): WorkflowSchema => {
			if (state.displayMode === WorkflowDisplayMode.Display) {
				return makePositionToElements(removePlus(clone(state.workflowSchema)), { x: 0, y: 0 }, 0) as WorkflowSchema;
			} else {
				return state.workflowSchema;
			}
		},
		editingWorkblockAddress: (state): string => {
			if (state.workflowEditingElementIndex != -1 && state.workflowEditingElementAddress !== '') {
				return state.workflowEditingElementAddress + '[' + state.workflowEditingElementIndex + ']';
			}

			return '';
		},
		editingWorkblock: (state): WorkflowSchema => {
			if (state.workflowEditingElementIndex != -1 && state.workflowEditingElementAddress !== '') {
				return get(state.workflowSchema, state.workflowEditingElementAddress + '[' + state.workflowEditingElementIndex + ']');
			}

			return <WorkflowSchema>{};
		},
		editingWorkblockActions: (state): WorkflowElementActions => {
			if (state.workflowEditingElementIndex != -1 && state.workflowEditingElementAddress !== '') {
				return get(state.workflowSchema, state.workflowEditingElementAddress + '[' + state.workflowEditingElementIndex + '].workblock');
			}

			return <WorkflowElementActions>{};
		},
		editingWorkblockTemplate: (state): WorkflowSchema => {
			if (state.workflowEditingElementIndex != -1 && state.workflowEditingElementAddress !== '') {
				return get(state.workflowSchema, state.workflowEditingElementAddress + '[' + state.workflowEditingElementIndex + '].template');
			}

			return <WorkflowSchema>{};
		},
		canUndo: (state): boolean => {
			return state.undoStack.length > 0;
		},
		canRedo: (state): boolean => {
			return state.redoStack.length > 0;
		},
	},
	actions: {
		async openWorkflowElementEditor(elementAddress: string, elementIndex: number) {
			this.$patch({
				workflowEditingElementAddress: elementAddress,
				workflowEditingElementIndex: elementIndex,
				elementSettingsOpen: true,
				workflowSettingsOpen: false,
			});
			const baseStore = useBaseStore();
			baseStore.secondaryPanelOpen = true;
		},
		async closeWorkflowElementEditor() {
			this.$patch({
				workflowEditingElementAddress: '',
				workflowEditingElementIndex: -1,
				elementSettingsOpen: false,
			});
			const baseStore = useBaseStore();
			baseStore.secondaryPanelOpen = false;
		},
		async openWorkflowSettingsEditor() {
			this.$patch({
				workflowSettingsOpen: true,
				elementSettingsOpen: false,
			});
			const baseStore = useBaseStore();
			baseStore.secondaryPanelOpen = true;
		},
		async closeWorkflowSettingsEditor() {
			this.workflowSettingsOpen = false;
			if (this.workflowEditingElementIndex >= 0 && this.workflowEditingElementAddress !== '') {
				this.elementSettingsOpen = true;
			} else {
				const baseStore = useBaseStore();
				baseStore.secondaryPanelOpen = false;
			}
		},
		async setDisplayMode(displayMode: WorkflowDisplayMode) {
			this.$patch({
				displayMode: displayMode,
			});
		},
		async toggleDetailsMode() {
			this.$patch({
				showDetail: !this.showDetail,
			});
		},
		async clearActiveWorkflow() {
			await this.makeUpdateToActiveWorkflow(initElement);
		},
		async makeUpdateToActiveWorkflow(newWorkflow: WorkflowSchema) {
			const newWorkflowObject = clone(newWorkflow);
			this.undoStack.unshift({
				snapshot: clone(this.workflowSchema),
				timestamp: +Date.now(),
			});

			this.workflowSchema = newWorkflowObject;

			if (this.historyCapacity && this.undoStack.length > this.historyCapacity) this.undoStack.splice(this.historyCapacity, Infinity);
			if (this.redoStack.length) this.redoStack.splice(0, this.redoStack.length);
		},
		async undo() {
			if (!this.canUndo) {
				return;
			}
			const state = this.undoStack.shift();

			if (state) {
				this.redoStack.unshift({
					snapshot: clone(this.workflowSchema),
					timestamp: +Date.now(),
				});
				this.workflowSchema = state.snapshot;
			}
		},
		async redo() {
			if (!this.canRedo) {
				return;
			}
			const state = this.redoStack.shift();

			if (state) {
				this.undoStack.unshift({
					snapshot: clone(this.workflowSchema),
					timestamp: +Date.now(),
				});
				this.workflowSchema = state.snapshot;
			}
		},
		async addElementToActiveWorkflowWithAddress(elementAddress: string, elementIndex: number) {
			if (typeof this.draggingElement === 'boolean') {
				return;
			}
			const { type_id } = this.draggingElement;
			const newElement = clone(this.workflowSchema);
			const parentElement = get(newElement, elementAddress);
			if (parentElement) {
				if (type_id === WorkflowObjectType.STEP) {
					const newWorkblockId = nanoid();
					parentElement.splice(elementIndex + 1, 0, {
						id: nanoid(),
						type: WorkflowObjectType.STEP,
						position: { x: 0, y: 0 },
						template: clone(this.draggingElement),
						workblock: {
							pre_actions: [],
							actions: this.draggingElement.action_id
								? ([
										{
											id: newWorkblockId,
											actionId: this.draggingElement.action_id.toString(),
											parameters: {},
										},
									] as WorkflowElementAction[])
								: [],
							post_actions: [],
						} as WorkflowElementActions,
					} satisfies WorkflowSchema);
					if (this.draggingElement.action_id) {
						console.dir('adding action');
						postAddActionToSchema(newWorkblockId, parseInt(this.draggingElement.action_id.toString()), convertJSAddressToPHPAddress(elementAddress + '.' + (elementIndex + 1) + '.workblock.actions.0'));
					}
					parentElement.splice(elementIndex + 2, 0, {
						id: nanoid(),
						type: WorkflowObjectType.PLUS,
						position: { x: 0, y: 0 },
					});
				} else if (type_id === WorkflowObjectType.EXIT) {
					console.dir();
					parentElement.splice(elementIndex + 1, 0, {
						id: nanoid(),
						type: WorkflowObjectType.EXIT,
						position: { x: 0, y: 0 },
						template: clone(this.draggingElement),
					} satisfies WorkflowSchema);
					parentElement.splice(elementIndex + 2, 0, {
						id: nanoid(),
						type: WorkflowObjectType.PLUS,
						position: { x: 0, y: 0 },
					});
					const exitLocation = convertJSAddressToPHPAddress(elementAddress + '.' + (elementIndex + 1));
					// add a new item to the workflowExits list;
					this.workflowExits[exitLocation] = {
						label: '',
						color: '',
					};
				} else if (type_id === WorkflowObjectType.ENTRY) {
					parentElement.splice(elementIndex + 1, 0, {
						id: nanoid(),
						type: WorkflowObjectType.ENTRY,
						position: { x: 0, y: 0 },
						template: clone(this.draggingElement),
					} satisfies WorkflowSchema);
					parentElement.splice(elementIndex + 2, 0, {
						id: nanoid(),
						type: WorkflowObjectType.PLUS,
						position: { x: 0, y: 0 },
					});
				} else if (type_id === WorkflowObjectType.HALT) {
					parentElement.splice(elementIndex + 1, 0, {
						id: nanoid(),
						type: WorkflowObjectType.HALT,
						position: { x: 0, y: 0 },
						template: clone(this.draggingElement),
					} satisfies WorkflowSchema);
					parentElement.splice(elementIndex + 2, 0, {
						id: nanoid(),
						type: WorkflowObjectType.PLUS,
						position: { x: 0, y: 0 },
					});
				} else if (type_id === WorkflowObjectType.BRANCH) {
					const children = [];
					for (let i = 0; i < 2; i++) {
						children.push({
							id: nanoid(),
							type: WorkflowObjectType.ROOT,
							position: { x: 0, y: 0 },
							children: [
								{
									id: nanoid(),
									type: WorkflowObjectType.PLUS,
									position: { x: 0, y: 0 },
								},
							],
						});
					}
					parentElement.splice(elementIndex + 1, 0, {
						id: nanoid(),
						type: WorkflowObjectType.BRANCH,
						position: { x: 0, y: 0 },
						template: clone(this.draggingElement),
						children,
					});
					parentElement.splice(elementIndex + 2, 0, {
						id: nanoid(),
						type: WorkflowObjectType.PLUS,
						position: { x: 0, y: 0 },
					});
				}
			}
			const newElementWithPos = makePositionToElements(newElement, { x: 0, y: 0 }, 0) as WorkflowSchema;
			await this.makeUpdateToActiveWorkflow(newElementWithPos);
			if (this.elementSettingsOpen && elementAddress === this.workflowEditingElementAddress) {
				this.workflowEditingElementIndex = this.workflowEditingElementIndex + 2;
			}
			this.draggingElement = false;
		},
		async deleteElementFromActiveWorkflow(elementAddress: string, elementIndex: number) {
			if (parseInt(this.editingWorkblock.type.toString()) === WorkflowObjectType.STEP) {
				for (const actionSections in WorkblockSections) {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-expect-error
					for (const action of this.editingWorkblockActions[actionSections]) {
						if (action.actionId && parseInt(action.actionId) === ActionTypes.INITIATE_FORM) {
							for (const workflowFormsAddress in this.workflowForms) {
								if (this.workflowForms[workflowFormsAddress].id == action.id) {
									delete this.workflowForms[workflowFormsAddress];
								}
							}
						} else if (action.actionId && parseInt(action.actionId) === ActionTypes.CREATE_TRIGGER) {
							for (const workflowTriggersAddress in this.workflowTriggers) {
								if (this.workflowTriggers[workflowTriggersAddress].id == action.id) {
									delete this.workflowForms[workflowTriggersAddress];
								}
							}
						}
					}
				}
			} else if (parseInt(this.editingWorkblock.type.toString()) === WorkflowObjectType.EXIT) {
				if (convertJSAddressToPHPAddress(this.editingWorkblockAddress) in this.workflowExits) {
					delete this.workflowExits[convertJSAddressToPHPAddress(this.editingWorkblockAddress)];
				}
			}

			const deletingWorkblockAddress = elementAddress + '[' + elementIndex + ']';
			if (deletingWorkblockAddress === this.editingWorkblockAddress) {
				await this.closeWorkflowElementEditor();
			}
			const newSchema = clone(this.workflowSchema);
			get(newSchema, elementAddress).splice(elementIndex, 2); // remove the workblock AND the plus after it
			const newElementWithPos = makePositionToElements(newSchema, { x: 0, y: 0 }, 0) as WorkflowSchema;

			await this.makeUpdateToActiveWorkflow(newElementWithPos);
		},
		async triggerSidePanelHasClosed() {
			await this.closeWorkflowElementEditor();
			await this.closeWorkflowSettingsEditor();
		},
		setLoadingStart() {
			const baseStore = useBaseStore();
			baseStore.setLoadingStart();
			this.loading = true;
		},
		setLoadingFinished() {
			const baseStore = useBaseStore();
			baseStore.setLoadingFinished();
			this.loading = false;
		},
		async getWorkflows(count = 30, page = 1) {
			this.setLoadingStart();
			const graphQlResult = await useGraphQL.query(GET_WORKFLOWS, {
				first: count,
				page: page,
			});
			if (graphQlResult.getWorkflows) {
				this.workflows = graphQlResult.getWorkflows.data satisfies Workflow[];
			}
			this.setLoadingFinished();
		},
		async getWorkblockTemplates() {
			const graphQlResult = await useGraphQL.query(GET_WORKBLOCK_TEMPLATES, {});
			if (graphQlResult.getWorkblockTemplates) {
				const templates = JSON.parse(JSON.stringify(graphQlResult.getWorkblockTemplates));
				for (const templateId in templates) {
					delete templates[templateId].__typename;
					if (templates[templateId].settings) {
						templates[templateId].settings = JSON.parse(templates[templateId].settings) as WorkflowElementSettings;
					}
				}
				this.$patch({
					workblockTemplates: templates as WorkblockTemplate[],
				});
			}
		},
		async getWorkblockActions() {
			const graphQlResult = await useGraphQL.query(GET_WORKBLOCK_ACTIONS, {});
			if (graphQlResult.getWorkblockActions) {
				this.$patch({
					workblockActions: graphQlResult.getWorkblockActions as WorkblockAction[],
				});
			}
		},
		async getWorkblockExpressionFunctions() {
			const graphQlResult = await useGraphQL.query(GET_WORKBLOCK_EXPRESSION_FUNCTIONS, {});
			if (graphQlResult.getWorkblockExpressionFunctions) {
				this.workblockExpressionFunctions = graphQlResult.getWorkblockExpressionFunctions;
			}
		},
		async getWorkflowById(workflowId: string) {
			this.setLoadingStart();
			await this.clearLoadedWorkflow();
			const graphQlResult = await useGraphQL.query(GET_WORKFLOW_BY_ID, {
				workflow_id: workflowId,
			});
			if (graphQlResult.getWorkflowById) {
				this.$patch({
					workflow: graphQlResult.getWorkflowById,
					workflowSettings: graphQlResult.getWorkflowById?.settings === '[]' ? {} : safeUnpack<WorkflowSettings>(graphQlResult.getWorkflowById?.settings, {}),
					workflowSchema: safeUnpack<WorkflowSchema>(graphQlResult.getWorkflowById.current_revision?.schema, clone(initElement)),
					workflowForms: convertEmptyArraysToObjects(safeUnpack<WorkflowForms>(graphQlResult.getWorkflowById?.current_revision?.forms, {})),
					workflowTriggers: safeUnpack<WorkflowTriggers>(graphQlResult.getWorkflowById?.current_revision?.triggers, {}),
					workflowVariables: safeUnpack<WorkflowVariables>(graphQlResult.getWorkflowById?.current_revision?.variables, {}),
					workflowExits: safeUnpack<WorkflowTriggers>(graphQlResult.getWorkflowById?.current_revision?.exits, {}),
					loaded: true,
				});
				if (graphQlResult.getWorkflowById?.current_revision?.variables === '[]') {
					this.workflowVariables = {};
				}
			}
			this.setLoadingFinished();
		},
		async createNewWorkflow(workflowData: CreateWorkflowType) {
			this.setLoadingStart();
			return await useGraphQL
				.mutate(CREATE_NEW_WORKFLOW, {
					input: workflowData,
				})
				.then((graphQlResult) => {
					if (graphQlResult?.createWorkflow) {
						return graphQlResult.createWorkflow.id;
					}
				})
				.finally(() => {
					this.setLoadingFinished();
				});
		},
		async updateWorkflow() {
			this.setLoadingStart();
			useGraphQL
				.mutate(UPDATE_WORKFLOW, {
					input: {
						id: this.workflow.id,
						name: this.workflow.name,
						reference_name: this.workflow.reference_name,
						description: this.workflow.description,
						status_id: this.workflow.status_id,
						schema: JSON.stringify(this.workflowSchema),
						settings: this.workflowSettings ? JSON.stringify(this.workflowSettings) : null,
						variables: this.workflowVariables ? JSON.stringify(this.workflowVariables) : null,
					},
				})
				.then((graphQlResult) => {
					if (graphQlResult?.updateWorkflow) {
						this.$patch({
							workflow: graphQlResult?.updateWorkflow,
							workflowSchema: safeUnpack<WorkflowSchema>(graphQlResult.updateWorkflow?.current_revision?.schema, clone(initElement)),
							workflowSettings: graphQlResult.updateWorkflow?.settings === '[]' ? {} : safeUnpack<WorkflowSettings>(graphQlResult.updateWorkflow?.settings, {}),
							workflowForms: convertEmptyArraysToObjects(safeUnpack<WorkflowForms>(graphQlResult.updateWorkflow?.current_revision?.forms, {})),
							workflowTriggers: safeUnpack<WorkflowTriggers>(graphQlResult.updateWorkflow?.current_revision?.triggers, {}),
							workflowVariables: safeUnpack<WorkflowVariables>(graphQlResult.updateWorkflow?.current_revision?.variables, {}),
							workflowExits: safeUnpack<WorkflowTriggers>(graphQlResult.updateWorkflow?.current_revision?.exits, {}),
						});
						if (graphQlResult.updateWorkflow?.current_revision?.variables === '[]') {
							this.workflowVariables = {};
						}
						useNotify.icon({ icon: AlertIcons.Success }).title('Workflow successfully updated').fire();
					}
				})
				.finally(() => {
					this.setLoadingFinished();
				});
		},
		async getActiveWorkflowDropdown(count = 30, page = 1) {
			const graphQlResult = await useGraphQL.query(GET_ACTIVE_WORKFLOWS_DROPDOWN, {
				first: count,
				page: page,
			});
			if (graphQlResult.getWorkflows) {
				const workflowList = [];
				for (const workflow of graphQlResult.getWorkflows.data) {
					workflowList.push({
						label: workflow.name + (workflow.description ? ' [' + workflow.description + ']' : ''),
						value: workflow.id,
					});
				}
				return workflowList;
			}

			return [];
		},
		getDropdownList() {
			return this.getActiveWorkflowDropdown();
		},
		async getSelectedDropdownOption(workflowId: string) {
			for (const workflowDropdownOption of await this.getActiveWorkflowDropdown()) {
				if (String(workflowDropdownOption.value) === String(workflowId)) {
					return workflowDropdownOption;
				}
			}
		},
		getWorkblockActionDropdownList() {
			const actionsToShow = [];
			for (const action of this.workblockActions) {
				actionsToShow.push({
					label: action.name,
					value: action.id,
				});
			}
			return actionsToShow;
		},
		getSelectedWorkblockActionDropdownOption(workblockActionId: string) {
			for (const action of this.workblockActions) {
				if (String(action.id) === String(workblockActionId)) {
					return {
						label: action.name,
						value: action.id,
					};
				}
			}

			return { label: 'Unknown action!' };
		},
		getActiveWorkflowFormsDropdownList() {
			const workflowFormsToShow = [];
			for (const form in this.workflowForms) {
				workflowFormsToShow.push({
					label: this.workflowForms[form].parameters.form_key /* + ' - [Form ID = "' + this.workflowForms[form].parameters.form_id + '"]'*/,
					value: this.workflowForms[form].parameters.form_id,
				});
			}
			return workflowFormsToShow;
		},
		async getSelectedActiveWorkflowFormsDropdownList(workflowFormKey: string) {
			for (const form in this.workflowForms) {
				if (this.workflowForms[form] && this.workflowForms[form].parameters.form_id.toString() === workflowFormKey) {
					return {
						label: this.workflowForms[form].parameters.form_key /* + ' - [Form ID = "' + this.workflowForms[form].parameters.form_id + '"]'*/,
						value: this.workflowForms[form].parameters.form_id,
					};
				}
			}

			return { label: 'Unknown action!' };
		},

		getActiveWorkflowFormTriggersDropdownList() {
			const workflowFormsToShow = [];
			for (const form in this.workflowForms) {
				workflowFormsToShow.push({
					label: `'${this.workflowForms[form].parameters.form_key}' is activated` /* - [Form ID = "' + this.workflowForms[form].parameters.form_id + '"]'*/,
					value: this.workflowForms[form].parameters.form_key + '.activated',
				});
				workflowFormsToShow.push({
					label: `'${this.workflowForms[form].parameters.form_key}' is in progress` /* - [Form ID = "' + this.workflowForms[form].parameters.form_id + '"]'*/,
					value: this.workflowForms[form].parameters.form_key + '.in_progress',
				});
				workflowFormsToShow.push({
					label: `'${this.workflowForms[form].parameters.form_key}' is submitted` /* - [Form ID = "' + this.workflowForms[form].parameters.form_id + '"]'*/,
					value: this.workflowForms[form].parameters.form_key + '.submitted',
				});
			}
			return workflowFormsToShow;
		},
		async getSelectedActiveWorkflowFormTriggersDropdownList(workflowFormTriggerKey: string) {
			for (const form in this.workflowForms) {
				if (this.workflowForms[form] && this.workflowForms[form].parameters.form_key.toString() + '.activated' === workflowFormTriggerKey) {
					return {
						label: `'${this.workflowForms[form].parameters.form_key}' is activated` /* - [Form ID = "' + this.workflowForms[form].parameters.form_id + '"]'*/,
						value: this.workflowForms[form].parameters.form_key + '.activated',
					};
				}
				if (this.workflowForms[form] && this.workflowForms[form].parameters.form_key.toString() + '.in_progress' === workflowFormTriggerKey) {
					return {
						label: `'${this.workflowForms[form].parameters.form_key}' is in progress` /* - [Form ID = "' + this.workflowForms[form].parameters.form_id + '"]'*/,
						value: this.workflowForms[form].parameters.form_key + '.in_progress',
					};
				}
				if (this.workflowForms[form] && this.workflowForms[form].parameters.form_key.toString() + '.submitted' === workflowFormTriggerKey) {
					return {
						label: `'${this.workflowForms[form].parameters.form_key}' is submitted` /* - [Form ID = "' + this.workflowForms[form].parameters.form_id + '"]'*/,
						value: this.workflowForms[form].parameters.form_key + '.submitted',
					};
				}
			}

			return { label: 'Unknown action!' };
		},
		getActiveWorkflowTriggersDropdownList() {
			const workflowTriggersToShow = [];
			for (const trigger in this.workflowTriggers) {
				workflowTriggersToShow.push({
					label: this.workflowTriggers[trigger].parameters.name + ' - ["' + this.workflowTriggers[trigger].parameters.reference_name + '"]',
					value: this.workflowTriggers[trigger].parameters.reference_name,
				});
			}
			return workflowTriggersToShow;
		},
		async getSelectedActiveWorkflowTriggersDropdownList(workflowTriggerKey: string) {
			for (const trigger in this.workflowTriggers) {
				if (this.workflowTriggers[trigger] && this.workflowTriggers[trigger].parameters.reference_name.toString() === workflowTriggerKey) {
					return {
						label: this.workflowTriggers[trigger].parameters.name + ' - ["' + this.workflowTriggers[trigger].parameters.reference_name + '"]',
						value: this.workflowTriggers[trigger].parameters.reference_name,
					};
				}
			}

			return { label: 'Unknown action!' };
		},
		addNewWorkflowVariable(variableAddressToCreateVariable: string | boolean, isGroup: boolean = false) {
			const variableName = (isGroup ? 'folder_' : 'variable_') + nanoid(6);
			if (!variableAddressToCreateVariable) {
				//create it in the base array
				this.workflowVariables[variableName] = {
					id: nanoid(),
					name: variableName,
					type: isGroup ? undefined : ActivityTemplateVariableType.STRING,
					attributes: {
						description: '',
						group: isGroup,
						list: false,
						hidden: false,
						read_only: false,
						tracked: true,
					},
					children: {},
					initial_value: undefined,
				} satisfies WorkflowVariable;

				return variableName;
			}

			const locationToAdd = get(this.workflowVariables, variableAddressToCreateVariable + '.children');
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			locationToAdd[variableName] = {
				id: nanoid(),
				name: variableName,
				type: isGroup ? undefined : ActivityTemplateVariableType.STRING,
				attributes: {
					description: '',
					group: isGroup,
					list: false,
					hidden: false,
					read_only: false,
					tracked: true,
				},
				children: {},
				initial_value: undefined,
			} satisfies WorkflowVariable;

			return variableAddressToCreateVariable + '.children.' + variableName;
		},
		async clearLoadedWorkflow() {
			this.$patch({
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				loaded: false,
				showDetail: false,
				displayMode: WorkflowDisplayMode.Builder,
				workflow: <Workflow>{},
				workflowSchema: <WorkflowSchema>{},
				workflowSettings: <WorkflowSettings>{},
				workflowForms: <WorkflowForms>{},
				workflowTriggers: <WorkflowTriggers>{},
				workflowVariables: <WorkflowVariables>{},
				workflowExits: <WorkflowExit>{},
				elementSettingsOpen: false,
				workflowSettingsOpen: false,
				workflowEditingElementAddress: '',
				workflowEditingElementIndex: -1,
				undoStack: <UseRefHistoryRecord<WorkflowSchema>[]>[],
				redoStack: <UseRefHistoryRecord<WorkflowSchema>[]>[],
				historyCapacity: <number>20,
				draggingElement: <WorkblockTemplate | boolean>false,
				workblockTemplates: <WorkblockTemplate[]>[],
				workblockActions: <WorkblockAction[]>[],
				workblockExpressionFunctions: <WorkblockExpressionFunction[]>[],
				workflowProcessHistory: [],
				workflowElementsModal: {
					open: false,
					insertAddress: '',
					insertIndex: -1,
				},
			});
		},
	},
});

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useWorkflowStore, import.meta.hot));
}
