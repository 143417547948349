export default function deepMergeWithSpread<T extends object, U extends object>(obj1: T, obj2: U): T & U {
	const result = { ...obj1 } as T & U;

	for (const key in obj2) {
		if (Object.prototype.hasOwnProperty.call(obj2, key)) {
			// Explicitly cast the key to unknown before converting to keyof T and keyof U
			const keyT = key as unknown as keyof T;
			const keyU = key as unknown as keyof U;

			const value1 = obj1[keyT];
			const value2 = obj2[keyU];

			if (isPlainObject(value1) && isPlainObject(value2)) {
				// Recursively merge objects
				result[key as keyof (T & U)] = deepMergeWithSpread(value1 as object, value2 as object) as (T & U)[keyof (T & U)];
			} else {
				// Assign value from obj2
				result[key as keyof (T & U)] = value2 as (T & U)[keyof (T & U)];
			}
		}
	}

	return result;
}

function isPlainObject(value: unknown): value is object {
	return typeof value === 'object' && value !== null && !Array.isArray(value);
}
