import gql from 'graphql-tag';

export const CREATE_NEW_ACTIVITY = gql`
	mutation Mutation($input: CreateActivityInput!) {
		createActivity(input: $input) {
			id
		}
	}
`;

export const DELETE_ACTIVITY_INTERACTION = gql`
	mutation Mutation($input: DeleteActivityInteractionInput!) {
		deleteActivityInteraction(input: $input)
	}
`;

export const CREATE_NEW_ACTIVITY_INTERACTION = gql`
	mutation Mutation($input: CreateActivityInteractionInput!) {
		createActivityInteraction(input: $input) {
			id
			visibility
			content
			meta
			content_history
			attachments {
				id
				path
				meta
				created_at
				creator {
					id
					first_name
					middle_name
					last_name
				}
			}
			created_at
			creator {
				id
				first_name
				middle_name
				last_name
			}
			updated_at
			updater {
				id
				first_name
				middle_name
				last_name
			}
		}
	}
`;

export const UPDATE_ACTIVITY = gql`
	mutation Mutation($input: UpdateFormInput!) {
		updateForm(input: $input) {
			id
			type_id
			status_id
			settings
			name
			reference_name
			description
			current_version_id
			current_version_replaces
			current_version {
				id
				form_id
				version
				schema
				created_at
				created_by
				updated_at
				updated_by
			}
			version_history
			published_at
			published_by
			archived_at
			archived_by
			created_at
			created_by
			updated_at
			updated_by
			current_version_at
			current_version_by
		}
	}
`;

export const DELETE_ACTIVITY = gql`
	mutation Mutation($input: DeleteActivityInput!) {
		deleteActivity(input: $input)
	}
`;

export const ARCHIVE_ACTIVITY = gql`
	mutation Mutation($input: DeleteActivityInput!) {
		deleteActivity(input: $input)
	}
`;
